import React from "react"
import { Link, graphql } from "gatsby"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { TeaserCompact } from "../components/teaser-compact"
import { FluidObject } from "gatsby-image"
import { Teaser } from "../components/teaser"

interface Props {
    pageContext: {
        tag: string
    },
    data: {
        allMarkdownRemark: {
            edges: []
        },
        site: {
            siteMetadata: {
                title: string
            }
        }
    },
    location: Location
}

const Tags = ({ pageContext, data, location }: Props) => {
    const { tag } = pageContext
    const { edges: posts } = data.allMarkdownRemark
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={`Alla artiklar för #${tag}`} />

            <div className="text-lg italic">Tagg</div>
            <h1 className="text-5xl mb-10">#{tag}</h1>

            <div className="max-w-2xl mx-auto">

                {posts.map(({ node }: {
                    node: {
                        excerpt: string,
                        timeToRead: number,
                        fields: {
                            slug: string
                        },
                        frontmatter: {
                            title: string,
                            description: string,
                            date: Date,
                            featuredImage: {
                                childImageSharp: {
                                    fluid: FluidObject
                                }
                            },
                            showFeaturedImage: boolean,
                            category: string,
                            published: boolean
                        }
                    }
                }) => {
                    const { title, date, featuredImage, showFeaturedImage = false, category: postCategory, published } = node.frontmatter
                    const { excerpt, timeToRead } = node
                    const { slug } = node.fields

                    const category = postCategory === null ? 'default' : postCategory

                    return (
                        <Teaser data={{
                            title,
                            excerpt,
                            timeToRead,
                            date,
                            slug,
                            category,
                            image: featuredImage,
                            showFeaturedImage,
                            published
                        }}
                            key={node.fields.slug}
                        />
                    )
                })}

            </div>


            <Link className="no-underline pt-8 block text-center" to="/tags">Alla taggar</Link>
        </Layout>
    )
}

export default Tags

export const pageQuery = graphql`
  query TagPageQuery($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY HH:ss")
            title
            description
            published
            category
            showFeaturedImage
            featuredImage {
              childImageSharp {
                  fluid(maxWidth: 768, maxHeight: 220) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
          }
        }
      }
    }
  }
`
import React from "react"
import { Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image";
import { format, formatDistanceToNow } from "date-fns";
import { sv } from 'date-fns/locale';
import { DraftBadge } from "./article/DraftBadgeComponent";

interface Props {
    data: {
        title: string,
        excerpt: string,
        timeToRead: number,
        date: Date,
        slug: string,
        category?: string,
        image?: {
            childImageSharp: {
                fluid: FluidObject
            }
        },
        showFeaturedImage: boolean
        published: boolean
    }
}

const Teaser = ({ data }: Props) => {
    const { title, excerpt, timeToRead, date, slug, image, showFeaturedImage, published } = data

    return (
        <article className="pb-6 mb-10 border-b-2 border-gray-200 dark:border-gray-800">
            {(image && showFeaturedImage) &&
                <Img fluid={image.childImageSharp.fluid} className="mb-4" />
            }

            <header>
                <h2 className="text-4xl leading-tight font-bold">
                    <Link className="text-gray-700 hover:text-blue-500 dark:text-dark-primary dark-hover:text-blue-500" to={slug}>
                        {title}
                        <DraftBadge published={published} />
                    </Link>
                </h2>
            </header>

            <section>
                <div className="text-base text-gray-700 py-4 dark:text-dark-secondary">
                    <span title={format(new Date(date), 'iiii d MMMM yyyy', { locale: sv })}>
                        {date ? formatDistanceToNow(new Date(date), { locale: sv, addSuffix: true }) : ''}
                    </span>
                    <span className="mx-2">-</span><span>{timeToRead} min lästid</span>
                </div>
                <p>
                    {excerpt}
                </p>
            </section>
        </article>
    )
}

export { Teaser }
